.loader {
    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &-spinner{
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid rgb(0, 174, 255);
      border-bottom: 16px solid rgb(0, 174, 255);
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite;
      animation: spin .8s linear infinite;
    }
  }
  
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }