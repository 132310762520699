* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: all .4s ease;
}

[dir="ltr"] h1,h2,h3,h4,h5,h6{
  font-family: "Tajawal" !important;
}

[dir="rtl"] h1,h2,h3,h4,h5,h6{
  font-family: "Tajawal" !important;
}

h1{
  font-size: 42px !important;
}
h2{
  font-size: 37px !important;
}
h3{
  font-size: 22px !important;
}


::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1d4a5b;
  border-radius: 10px;
}

@media screen and (max-width: 500px) {
h1{
  font-size: 27px !important;
}
}
